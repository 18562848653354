.fp {
  position: relative;
  display: inline-block;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat
}

.fp:before {
  content: "\00a0"
}

.fp {
  line-height: 1em;
  width: 1.33333em
}

.fp.fp-square {
  line-height: 1em;
  width: 1em
}

.fp.fp-rounded {
  border-radius: .16667em
}

.fp.fp-md {
  line-height: 1.5em;
  width: 2em
}

.fp.fp-md.fp-square {
  line-height: 1.5em;
  width: 1.5em
}

.fp.fp-md.fp-rounded {
  border-radius: .25em
}

.fp.fp-lg {
  line-height: 2em;
  width: 2.66667em
}

.fp.fp-lg.fp-square {
  line-height: 2em;
  width: 2em
}

.fp.fp-lg.fp-rounded {
  border-radius: .25em
}

.ac {
  background-image: url("/assets/flags/4x3/ac.svg")
}

.ac.fp-square {
  background-image: url("/assets/flags/1x1/ac.svg")
}

.ad {
  background-image: url("/assets/flags/4x3/ad.svg")
}

.ad.fp-square {
  background-image: url("/assets/flags/1x1/ad.svg")
}

.ae {
  background-image: url("/assets/flags/4x3/ae.svg")
}

.ae.fp-square {
  background-image: url("/assets/flags/1x1/ae.svg")
}

.af {
  background-image: url("/assets/flags/4x3/af.svg")
}

.af.fp-square {
  background-image: url("/assets/flags/1x1/af.svg")
}

.ag {
  background-image: url("/assets/flags/4x3/ag.svg")
}

.ag.fp-square {
  background-image: url("/assets/flags/1x1/ag.svg")
}

.ai {
  background-image: url("/assets/flags/4x3/ai.svg")
}

.ai.fp-square {
  background-image: url("/assets/flags/1x1/ai.svg")
}

.al {
  background-image: url("/assets/flags/4x3/al.svg")
}

.al.fp-square {
  background-image: url("/assets/flags/1x1/al.svg")
}

.am {
  background-image: url("/assets/flags/4x3/am.svg")
}

.am.fp-square {
  background-image: url("/assets/flags/1x1/am.svg")
}

.ao {
  background-image: url("/assets/flags/4x3/ao.svg")
}

.ao.fp-square {
  background-image: url("/assets/flags/1x1/ao.svg")
}

.ar {
  background-image: url("/assets/flags/4x3/ar.svg")
}

.ar.fp-square {
  background-image: url("/assets/flags/1x1/ar.svg")
}

.as {
  background-image: url("/assets/flags/4x3/as.svg")
}

.as.fp-square {
  background-image: url("/assets/flags/1x1/as.svg")
}

.at {
  background-image: url("/assets/flags/4x3/at.svg")
}

.at.fp-square {
  background-image: url("/assets/flags/1x1/at.svg")
}

.au {
  background-image: url("/assets/flags/4x3/au.svg")
}

.au.fp-square {
  background-image: url("/assets/flags/1x1/au.svg")
}

.aw {
  background-image: url("/assets/flags/4x3/aw.svg")
}

.aw.fp-square {
  background-image: url("/assets/flags/1x1/aw.svg")
}

.ax {
  background-image: url("/assets/flags/4x3/ax.svg")
}

.ax.fp-square {
  background-image: url("/assets/flags/1x1/ax.svg")
}

.az {
  background-image: url("/assets/flags/4x3/az.svg")
}

.az.fp-square {
  background-image: url("/assets/flags/1x1/az.svg")
}

.ba {
  background-image: url("/assets/flags/4x3/ba.svg")
}

.ba.fp-square {
  background-image: url("/assets/flags/1x1/ba.svg")
}

.bb {
  background-image: url("/assets/flags/4x3/bb.svg")
}

.bb.fp-square {
  background-image: url("/assets/flags/1x1/bb.svg")
}

.bd {
  background-image: url("/assets/flags/4x3/bd.svg")
}

.bd.fp-square {
  background-image: url("/assets/flags/1x1/bd.svg")
}

.be {
  background-image: url("/assets/flags/4x3/be.svg")
}

.be.fp-square {
  background-image: url("/assets/flags/1x1/be.svg")
}

.bf {
  background-image: url("/assets/flags/4x3/bf.svg")
}

.bf.fp-square {
  background-image: url("/assets/flags/1x1/bf.svg")
}

.bg {
  background-image: url("/assets/flags/4x3/bg.svg")
}

.bg.fp-square {
  background-image: url("/assets/flags/1x1/bg.svg")
}

.bh {
  background-image: url("/assets/flags/4x3/bh.svg")
}

.bh.fp-square {
  background-image: url("/assets/flags/1x1/bh.svg")
}

.bi {
  background-image: url("/assets/flags/4x3/bi.svg")
}

.bi.fp-square {
  background-image: url("/assets/flags/1x1/bi.svg")
}

.bj {
  background-image: url("/assets/flags/4x3/bj.svg")
}

.bj.fp-square {
  background-image: url("/assets/flags/1x1/bj.svg")
}

.bl {
  background-image: url("/assets/flags/4x3/bl.svg")
}

.bl.fp-square {
  background-image: url("/assets/flags/1x1/bl.svg")
}

.bm {
  background-image: url("/assets/flags/4x3/bm.svg")
}

.bm.fp-square {
  background-image: url("/assets/flags/1x1/bm.svg")
}

.bn {
  background-image: url("/assets/flags/4x3/bn.svg")
}

.bn.fp-square {
  background-image: url("/assets/flags/1x1/bn.svg")
}

.bo {
  background-image: url("/assets/flags/4x3/bo.svg")
}

.bo.fp-square {
  background-image: url("/assets/flags/1x1/bo.svg")
}

.bq {
  background-image: url("/assets/flags/4x3/bq.svg")
}

.bq.fp-square {
  background-image: url("/assets/flags/1x1/bq.svg")
}

.br {
  background-image: url("/assets/flags/4x3/br.svg")
}

.br.fp-square {
  background-image: url("/assets/flags/1x1/br.svg")
}

.bs {
  background-image: url("/assets/flags/4x3/bs.svg")
}

.bs.fp-square {
  background-image: url("/assets/flags/1x1/bs.svg")
}

.bt {
  background-image: url("/assets/flags/4x3/bt.svg")
}

.bt.fp-square {
  background-image: url("/assets/flags/1x1/bt.svg")
}

.bv {
  background-image: url("/assets/flags/4x3/bv.svg")
}

.bv.fp-square {
  background-image: url("/assets/flags/1x1/bv.svg")
}

.bw {
  background-image: url("/assets/flags/4x3/bw.svg")
}

.bw.fp-square {
  background-image: url("/assets/flags/1x1/bw.svg")
}

.by {
  background-image: url("/assets/flags/4x3/by.svg")
}

.by.fp-square {
  background-image: url("/assets/flags/1x1/by.svg")
}

.bz {
  background-image: url("/assets/flags/4x3/bz.svg")
}

.bz.fp-square {
  background-image: url("/assets/flags/1x1/bz.svg")
}

.ca {
  background-image: url("/assets/flags/4x3/ca.svg")
}

.ca.fp-square {
  background-image: url("/assets/flags/1x1/ca.svg")
}

.cc {
  background-image: url("/assets/flags/4x3/cc.svg")
}

.cc.fp-square {
  background-image: url("/assets/flags/1x1/cc.svg")
}

.cd {
  background-image: url("/assets/flags/4x3/cd.svg")
}

.cd.fp-square {
  background-image: url("/assets/flags/1x1/cd.svg")
}

.cf {
  background-image: url("/assets/flags/4x3/cf.svg")
}

.cf.fp-square {
  background-image: url("/assets/flags/1x1/cf.svg")
}

.cg {
  background-image: url("/assets/flags/4x3/cg.svg")
}

.cg.fp-square {
  background-image: url("/assets/flags/1x1/cg.svg")
}

.ch {
  background-image: url("/assets/flags/4x3/ch.svg")
}

.ch.fp-square {
  background-image: url("/assets/flags/1x1/ch.svg")
}

.ci {
  background-image: url("/assets/flags/4x3/ci.svg")
}

.ci.fp-square {
  background-image: url("/assets/flags/1x1/ci.svg")
}

.ck {
  background-image: url("/assets/flags/4x3/ck.svg")
}

.ck.fp-square {
  background-image: url("/assets/flags/1x1/ck.svg")
}

.cl {
  background-image: url("/assets/flags/4x3/cl.svg")
}

.cl.fp-square {
  background-image: url("/assets/flags/1x1/cl.svg")
}

.cm {
  background-image: url("/assets/flags/4x3/cm.svg")
}

.cm.fp-square {
  background-image: url("/assets/flags/1x1/cm.svg")
}

.cn {
  background-image: url("/assets/flags/4x3/cn.svg")
}

.cn.fp-square {
  background-image: url("/assets/flags/1x1/cn.svg")
}

.co {
  background-image: url("/assets/flags/4x3/co.svg")
}

.co.fp-square {
  background-image: url("/assets/flags/1x1/co.svg")
}

.cr {
  background-image: url("/assets/flags/4x3/cr.svg")
}

.cr.fp-square {
  background-image: url("/assets/flags/1x1/cr.svg")
}

.cu {
  background-image: url("/assets/flags/4x3/cu.svg")
}

.cu.fp-square {
  background-image: url("/assets/flags/1x1/cu.svg")
}

.cv {
  background-image: url("/assets/flags/4x3/cv.svg")
}

.cv.fp-square {
  background-image: url("/assets/flags/1x1/cv.svg")
}

.cw {
  background-image: url("/assets/flags/4x3/cw.svg")
}

.cw.fp-square {
  background-image: url("/assets/flags/1x1/cw.svg")
}

.cx {
  background-image: url("/assets/flags/4x3/cx.svg")
}

.cx.fp-square {
  background-image: url("/assets/flags/1x1/cx.svg")
}

.cy {
  background-image: url("/assets/flags/4x3/cy.svg")
}

.cy.fp-square {
  background-image: url("/assets/flags/1x1/cy.svg")
}

.cz {
  background-image: url("/assets/flags/4x3/cz.svg")
}

.cz.fp-square {
  background-image: url("/assets/flags/1x1/cz.svg")
}

.de {
  background-image: url("/assets/flags/4x3/de.svg")
}

.de.fp-square {
  background-image: url("/assets/flags/1x1/de.svg")
}

.dj {
  background-image: url("/assets/flags/4x3/dj.svg")
}

.dj.fp-square {
  background-image: url("/assets/flags/1x1/dj.svg")
}

.dk {
  background-image: url("/assets/flags/4x3/dk.svg")
}

.dk.fp-square {
  background-image: url("/assets/flags/1x1/dk.svg")
}

.dm {
  background-image: url("/assets/flags/4x3/dm.svg")
}

.dm.fp-square {
  background-image: url("/assets/flags/1x1/dm.svg")
}

.do {
  background-image: url("/assets/flags/4x3/do.svg")
}

.do.fp-square {
  background-image: url("/assets/flags/1x1/do.svg")
}

.dz {
  background-image: url("/assets/flags/4x3/dz.svg")
}

.dz.fp-square {
  background-image: url("/assets/flags/1x1/dz.svg")
}

.ec {
  background-image: url("/assets/flags/4x3/ec.svg")
}

.ec.fp-square {
  background-image: url("/assets/flags/1x1/ec.svg")
}

.ee {
  background-image: url("/assets/flags/4x3/ee.svg")
}

.ee.fp-square {
  background-image: url("/assets/flags/1x1/ee.svg")
}

.eg {
  background-image: url("/assets/flags/4x3/eg.svg")
}

.eg.fp-square {
  background-image: url("/assets/flags/1x1/eg.svg")
}

.eh {
  background-image: url("/assets/flags/4x3/eh.svg")
}

.eh.fp-square {
  background-image: url("/assets/flags/1x1/eh.svg")
}

.er {
  background-image: url("/assets/flags/4x3/er.svg")
}

.er.fp-square {
  background-image: url("/assets/flags/1x1/er.svg")
}

.es {
  background-image: url("/assets/flags/4x3/es.svg")
}

.es.fp-square {
  background-image: url("/assets/flags/1x1/es.svg")
}

.et {
  background-image: url("/assets/flags/4x3/et.svg")
}

.et.fp-square {
  background-image: url("/assets/flags/1x1/et.svg")
}

.eu {
  background-image: url("/assets/flags/4x3/eu.svg")
}

.eu.fp-square {
  background-image: url("/assets/flags/1x1/eu.svg")
}

.fi {
  background-image: url("/assets/flags/4x3/fi.svg")
}

.fi.fp-square {
  background-image: url("/assets/flags/1x1/fi.svg")
}

.fj {
  background-image: url("/assets/flags/4x3/fj.svg")
}

.fj.fp-square {
  background-image: url("/assets/flags/1x1/fj.svg")
}

.fk {
  background-image: url("/assets/flags/4x3/fk.svg")
}

.fk.fp-square {
  background-image: url("/assets/flags/1x1/fk.svg")
}

.fm {
  background-image: url("/assets/flags/4x3/fm.svg")
}

.fm.fp-square {
  background-image: url("/assets/flags/1x1/fm.svg")
}

.fo {
  background-image: url("/assets/flags/4x3/fo.svg")
}

.fo.fp-square {
  background-image: url("/assets/flags/1x1/fo.svg")
}

.fr {
  background-image: url("/assets/flags/4x3/fr.svg")
}

.fr.fp-square {
  background-image: url("/assets/flags/1x1/fr.svg")
}

.ga {
  background-image: url("/assets/flags/4x3/ga.svg")
}

.ga.fp-square {
  background-image: url("/assets/flags/1x1/ga.svg")
}

.gb-eng {
  background-image: url("/assets/flags/4x3/gb-eng.svg")
}

.gb-eng.fp-square {
  background-image: url("/assets/flags/1x1/gb-eng.svg")
}

.gb-nir {
  background-image: url("/assets/flags/4x3/gb-nir.svg")
}

.gb-nir.fp-square {
  background-image: url("/assets/flags/1x1/gb-nir.svg")
}

.gb-sct {
  background-image: url("/assets/flags/4x3/gb-sct.svg")
}

.gb-sct.fp-square {
  background-image: url("/assets/flags/1x1/gb-sct.svg")
}

.gb-wls {
  background-image: url("/assets/flags/4x3/gb-wls.svg")
}

.gb-wls.fp-square {
  background-image: url("/assets/flags/1x1/gb-wls.svg")
}

.gb-zet {
  background-image: url("/assets/flags/4x3/gb-zet.svg")
}

.gb-zet.fp-square {
  background-image: url("/assets/flags/1x1/gb-zet.svg")
}

.gb {
  background-image: url("/assets/flags/4x3/gb.svg")
}

.gb.fp-square {
  background-image: url("/assets/flags/1x1/gb.svg")
}

.gd {
  background-image: url("/assets/flags/4x3/gd.svg")
}

.gd.fp-square {
  background-image: url("/assets/flags/1x1/gd.svg")
}

.ge {
  background-image: url("/assets/flags/4x3/ge.svg")
}

.ge.fp-square {
  background-image: url("/assets/flags/1x1/ge.svg")
}

.gf {
  background-image: url("/assets/flags/4x3/gf.svg")
}

.gf.fp-square {
  background-image: url("/assets/flags/1x1/gf.svg")
}

.gg {
  background-image: url("/assets/flags/4x3/gg.svg")
}

.gg.fp-square {
  background-image: url("/assets/flags/1x1/gg.svg")
}

.gh {
  background-image: url("/assets/flags/4x3/gh.svg")
}

.gh.fp-square {
  background-image: url("/assets/flags/1x1/gh.svg")
}

.gi {
  background-image: url("/assets/flags/4x3/gi.svg")
}

.gi.fp-square {
  background-image: url("/assets/flags/1x1/gi.svg")
}

.gl {
  background-image: url("/assets/flags/4x3/gl.svg")
}

.gl.fp-square {
  background-image: url("/assets/flags/1x1/gl.svg")
}

.gm {
  background-image: url("/assets/flags/4x3/gm.svg")
}

.gm.fp-square {
  background-image: url("/assets/flags/1x1/gm.svg")
}

.gn {
  background-image: url("/assets/flags/4x3/gn.svg")
}

.gn.fp-square {
  background-image: url("/assets/flags/1x1/gn.svg")
}

.gp {
  background-image: url("/assets/flags/4x3/gp.svg")
}

.gp.fp-square {
  background-image: url("/assets/flags/1x1/gp.svg")
}

.gq {
  background-image: url("/assets/flags/4x3/gq.svg")
}

.gq.fp-square {
  background-image: url("/assets/flags/1x1/gq.svg")
}

.gr {
  background-image: url("/assets/flags/4x3/gr.svg")
}

.gr.fp-square {
  background-image: url("/assets/flags/1x1/gr.svg")
}

.gs {
  background-image: url("/assets/flags/4x3/gs.svg")
}

.gs.fp-square {
  background-image: url("/assets/flags/1x1/gs.svg")
}

.gt {
  background-image: url("/assets/flags/4x3/gt.svg")
}

.gt.fp-square {
  background-image: url("/assets/flags/1x1/gt.svg")
}

.gu {
  background-image: url("/assets/flags/4x3/gu.svg")
}

.gu.fp-square {
  background-image: url("/assets/flags/1x1/gu.svg")
}

.gw {
  background-image: url("/assets/flags/4x3/gw.svg")
}

.gw.fp-square {
  background-image: url("/assets/flags/1x1/gw.svg")
}

.gy {
  background-image: url("/assets/flags/4x3/gy.svg")
}

.gy.fp-square {
  background-image: url("/assets/flags/1x1/gy.svg")
}

.hk {
  background-image: url("/assets/flags/4x3/hk.svg")
}

.hk.fp-square {
  background-image: url("/assets/flags/1x1/hk.svg")
}

.hm {
  background-image: url("/assets/flags/4x3/hm.svg")
}

.hm.fp-square {
  background-image: url("/assets/flags/1x1/hm.svg")
}

.hn {
  background-image: url("/assets/flags/4x3/hn.svg")
}

.hn.fp-square {
  background-image: url("/assets/flags/1x1/hn.svg")
}

.hr {
  background-image: url("/assets/flags/4x3/hr.svg")
}

.hr.fp-square {
  background-image: url("/assets/flags/1x1/hr.svg")
}

.ht {
  background-image: url("/assets/flags/4x3/ht.svg")
}

.ht.fp-square {
  background-image: url("/assets/flags/1x1/ht.svg")
}

.hu {
  background-image: url("/assets/flags/4x3/hu.svg")
}

.hu.fp-square {
  background-image: url("/assets/flags/1x1/hu.svg")
}

.id {
  background-image: url("/assets/flags/4x3/id.svg")
}

.id.fp-square {
  background-image: url("/assets/flags/1x1/id.svg")
}

.ie {
  background-image: url("/assets/flags/4x3/ie.svg")
}

.ie.fp-square {
  background-image: url("/assets/flags/1x1/ie.svg")
}

.il {
  background-image: url("/assets/flags/4x3/il.svg")
}

.il.fp-square {
  background-image: url("/assets/flags/1x1/il.svg")
}

.im {
  background-image: url("/assets/flags/4x3/im.svg")
}

.im.fp-square {
  background-image: url("/assets/flags/1x1/im.svg")
}

.in {
  background-image: url("/assets/flags/4x3/in.svg")
}

.in.fp-square {
  background-image: url("/assets/flags/1x1/in.svg")
}

.io {
  background-image: url("/assets/flags/4x3/io.svg")
}

.io.fp-square {
  background-image: url("/assets/flags/1x1/io.svg")
}

.iq {
  background-image: url("/assets/flags/4x3/iq.svg")
}

.iq.fp-square {
  background-image: url("/assets/flags/1x1/iq.svg")
}

.ir {
  background-image: url("/assets/flags/4x3/ir.svg")
}

.ir.fp-square {
  background-image: url("/assets/flags/1x1/ir.svg")
}

.is {
  background-image: url("/assets/flags/4x3/is.svg")
}

.is.fp-square {
  background-image: url("/assets/flags/1x1/is.svg")
}

.it {
  background-image: url("/assets/flags/4x3/it.svg")
}

.it.fp-square {
  background-image: url("/assets/flags/1x1/it.svg")
}

.je {
  background-image: url("/assets/flags/4x3/je.svg")
}

.je.fp-square {
  background-image: url("/assets/flags/1x1/je.svg")
}

.jm {
  background-image: url("/assets/flags/4x3/jm.svg")
}

.jm.fp-square {
  background-image: url("/assets/flags/1x1/jm.svg")
}

.jo {
  background-image: url("/assets/flags/4x3/jo.svg")
}

.jo.fp-square {
  background-image: url("/assets/flags/1x1/jo.svg")
}

.jp {
  background-image: url("/assets/flags/4x3/jp.svg")
}

.jp.fp-square {
  background-image: url("/assets/flags/1x1/jp.svg")
}

.ke {
  background-image: url("/assets/flags/4x3/ke.svg")
}

.ke.fp-square {
  background-image: url("/assets/flags/1x1/ke.svg")
}

.kg {
  background-image: url("/assets/flags/4x3/kg.svg")
}

.kg.fp-square {
  background-image: url("/assets/flags/1x1/kg.svg")
}

.kh {
  background-image: url("/assets/flags/4x3/kh.svg")
}

.kh.fp-square {
  background-image: url("/assets/flags/1x1/kh.svg")
}

.ki {
  background-image: url("/assets/flags/4x3/ki.svg")
}

.ki.fp-square {
  background-image: url("/assets/flags/1x1/ki.svg")
}

.km {
  background-image: url("/assets/flags/4x3/km.svg")
}

.km.fp-square {
  background-image: url("/assets/flags/1x1/km.svg")
}

.kn {
  background-image: url("/assets/flags/4x3/kn.svg")
}

.kn.fp-square {
  background-image: url("/assets/flags/1x1/kn.svg")
}

.kp {
  background-image: url("/assets/flags/4x3/kp.svg")
}

.kp.fp-square {
  background-image: url("/assets/flags/1x1/kp.svg")
}

.kr {
  background-image: url("/assets/flags/4x3/kr.svg")
}

.kr.fp-square {
  background-image: url("/assets/flags/1x1/kr.svg")
}

.kw {
  background-image: url("/assets/flags/4x3/kw.svg")
}

.kw.fp-square {
  background-image: url("/assets/flags/1x1/kw.svg")
}

.ky {
  background-image: url("/assets/flags/4x3/ky.svg")
}

.ky.fp-square {
  background-image: url("/assets/flags/1x1/ky.svg")
}

.kz {
  background-image: url("/assets/flags/4x3/kz.svg")
}

.kz.fp-square {
  background-image: url("/assets/flags/1x1/kz.svg")
}

.la {
  background-image: url("/assets/flags/4x3/la.svg")
}

.la.fp-square {
  background-image: url("/assets/flags/1x1/la.svg")
}

.lb {
  background-image: url("/assets/flags/4x3/lb.svg")
}

.lb.fp-square {
  background-image: url("/assets/flags/1x1/lb.svg")
}

.lc {
  background-image: url("/assets/flags/4x3/lc.svg")
}

.lc.fp-square {
  background-image: url("/assets/flags/1x1/lc.svg")
}

.lgbt {
  background-image: url("/assets/flags/4x3/lgbt.svg")
}

.lgbt.fp-square {
  background-image: url("/assets/flags/1x1/lgbt.svg")
}

.li {
  background-image: url("/assets/flags/4x3/li.svg")
}

.li.fp-square {
  background-image: url("/assets/flags/1x1/li.svg")
}

.lk {
  background-image: url("/assets/flags/4x3/lk.svg")
}

.lk.fp-square {
  background-image: url("/assets/flags/1x1/lk.svg")
}

.lr {
  background-image: url("/assets/flags/4x3/lr.svg")
}

.lr.fp-square {
  background-image: url("/assets/flags/1x1/lr.svg")
}

.ls {
  background-image: url("/assets/flags/4x3/ls.svg")
}

.ls.fp-square {
  background-image: url("/assets/flags/1x1/ls.svg")
}

.lt {
  background-image: url("/assets/flags/4x3/lt.svg")
}

.lt.fp-square {
  background-image: url("/assets/flags/1x1/lt.svg")
}

.lu {
  background-image: url("/assets/flags/4x3/lu.svg")
}

.lu.fp-square {
  background-image: url("/assets/flags/1x1/lu.svg")
}

.lv {
  background-image: url("/assets/flags/4x3/lv.svg")
}

.lv.fp-square {
  background-image: url("/assets/flags/1x1/lv.svg")
}

.ly {
  background-image: url("/assets/flags/4x3/ly.svg")
}

.ly.fp-square {
  background-image: url("/assets/flags/1x1/ly.svg")
}

.ma {
  background-image: url("/assets/flags/4x3/ma.svg")
}

.ma.fp-square {
  background-image: url("/assets/flags/1x1/ma.svg")
}

.mc {
  background-image: url("/assets/flags/4x3/mc.svg")
}

.mc.fp-square {
  background-image: url("/assets/flags/1x1/mc.svg")
}

.md {
  background-image: url("/assets/flags/4x3/md.svg")
}

.md.fp-square {
  background-image: url("/assets/flags/1x1/md.svg")
}

.me {
  background-image: url("/assets/flags/4x3/me.svg")
}

.me.fp-square {
  background-image: url("/assets/flags/1x1/me.svg")
}

.mf {
  background-image: url("/assets/flags/4x3/mf.svg")
}

.mf.fp-square {
  background-image: url("/assets/flags/1x1/mf.svg")
}

.mg {
  background-image: url("/assets/flags/4x3/mg.svg")
}

.mg.fp-square {
  background-image: url("/assets/flags/1x1/mg.svg")
}

.mh {
  background-image: url("/assets/flags/4x3/mh.svg")
}

.mh.fp-square {
  background-image: url("/assets/flags/1x1/mh.svg")
}

.mk {
  background-image: url("/assets/flags/4x3/mk.svg")
}

.mk.fp-square {
  background-image: url("/assets/flags/1x1/mk.svg")
}

.ml {
  background-image: url("/assets/flags/4x3/ml.svg")
}

.ml.fp-square {
  background-image: url("/assets/flags/1x1/ml.svg")
}

.mm {
  background-image: url("/assets/flags/4x3/mm.svg")
}

.mm.fp-square {
  background-image: url("/assets/flags/1x1/mm.svg")
}

.mn {
  background-image: url("/assets/flags/4x3/mn.svg")
}

.mn.fp-square {
  background-image: url("/assets/flags/1x1/mn.svg")
}

.mo {
  background-image: url("/assets/flags/4x3/mo.svg")
}

.mo.fp-square {
  background-image: url("/assets/flags/1x1/mo.svg")
}

.mp {
  background-image: url("/assets/flags/4x3/mp.svg")
}

.mp.fp-square {
  background-image: url("/assets/flags/1x1/mp.svg")
}

.mq {
  background-image: url("/assets/flags/4x3/mq.svg")
}

.mq.fp-square {
  background-image: url("/assets/flags/1x1/mq.svg")
}

.mr {
  background-image: url("/assets/flags/4x3/mr.svg")
}

.mr.fp-square {
  background-image: url("/assets/flags/1x1/mr.svg")
}

.ms {
  background-image: url("/assets/flags/4x3/ms.svg")
}

.ms.fp-square {
  background-image: url("/assets/flags/1x1/ms.svg")
}

.mt {
  background-image: url("/assets/flags/4x3/mt.svg")
}

.mt.fp-square {
  background-image: url("/assets/flags/1x1/mt.svg")
}

.mu {
  background-image: url("/assets/flags/4x3/mu.svg")
}

.mu.fp-square {
  background-image: url("/assets/flags/1x1/mu.svg")
}

.mv {
  background-image: url("/assets/flags/4x3/mv.svg")
}

.mv.fp-square {
  background-image: url("/assets/flags/1x1/mv.svg")
}

.mw {
  background-image: url("/assets/flags/4x3/mw.svg")
}

.mw.fp-square {
  background-image: url("/assets/flags/1x1/mw.svg")
}

.mx {
  background-image: url("/assets/flags/4x3/mx.svg")
}

.mx.fp-square {
  background-image: url("/assets/flags/1x1/mx.svg")
}

.my {
  background-image: url("/assets/flags/4x3/my.svg")
}

.my.fp-square {
  background-image: url("/assets/flags/1x1/my.svg")
}

.mz {
  background-image: url("/assets/flags/4x3/mz.svg")
}

.mz.fp-square {
  background-image: url("/assets/flags/1x1/mz.svg")
}

.na {
  background-image: url("/assets/flags/4x3/na.svg")
}

.na.fp-square {
  background-image: url("/assets/flags/1x1/na.svg")
}

.nc {
  background-image: url("/assets/flags/4x3/nc.svg")
}

.nc.fp-square {
  background-image: url("/assets/flags/1x1/nc.svg")
}

.ne {
  background-image: url("/assets/flags/4x3/ne.svg")
}

.ne.fp-square {
  background-image: url("/assets/flags/1x1/ne.svg")
}

.nf {
  background-image: url("/assets/flags/4x3/nf.svg")
}

.nf.fp-square {
  background-image: url("/assets/flags/1x1/nf.svg")
}

.ng {
  background-image: url("/assets/flags/4x3/ng.svg")
}

.ng.fp-square {
  background-image: url("/assets/flags/1x1/ng.svg")
}

.ni {
  background-image: url("/assets/flags/4x3/ni.svg")
}

.ni.fp-square {
  background-image: url("/assets/flags/1x1/ni.svg")
}

.nl {
  background-image: url("/assets/flags/4x3/nl.svg")
}

.nl.fp-square {
  background-image: url("/assets/flags/1x1/nl.svg")
}

.no {
  background-image: url("/assets/flags/4x3/no.svg")
}

.no.fp-square {
  background-image: url("/assets/flags/1x1/no.svg")
}

.np {
  background-image: url("/assets/flags/4x3/np.svg")
}

.np.fp-square {
  background-image: url("/assets/flags/1x1/np.svg")
}

.nr {
  background-image: url("/assets/flags/4x3/nr.svg")
}

.nr.fp-square {
  background-image: url("/assets/flags/1x1/nr.svg")
}

.nu {
  background-image: url("/assets/flags/4x3/nu.svg")
}

.nu.fp-square {
  background-image: url("/assets/flags/1x1/nu.svg")
}

.nz {
  background-image: url("/assets/flags/4x3/nz.svg")
}

.nz.fp-square {
  background-image: url("/assets/flags/1x1/nz.svg")
}

.om {
  background-image: url("/assets/flags/4x3/om.svg")
}

.om.fp-square {
  background-image: url("/assets/flags/1x1/om.svg")
}

.pa {
  background-image: url("/assets/flags/4x3/pa.svg")
}

.pa.fp-square {
  background-image: url("/assets/flags/1x1/pa.svg")
}

.pe {
  background-image: url("/assets/flags/4x3/pe.svg")
}

.pe.fp-square {
  background-image: url("/assets/flags/1x1/pe.svg")
}

.pf {
  background-image: url("/assets/flags/4x3/pf.svg")
}

.pf.fp-square {
  background-image: url("/assets/flags/1x1/pf.svg")
}

.pg {
  background-image: url("/assets/flags/4x3/pg.svg")
}

.pg.fp-square {
  background-image: url("/assets/flags/1x1/pg.svg")
}

.ph {
  background-image: url("/assets/flags/4x3/ph.svg")
}

.ph.fp-square {
  background-image: url("/assets/flags/1x1/ph.svg")
}

.pk {
  background-image: url("/assets/flags/4x3/pk.svg")
}

.pk.fp-square {
  background-image: url("/assets/flags/1x1/pk.svg")
}

.pl {
  background-image: url("/assets/flags/4x3/pl.svg")
}

.pl.fp-square {
  background-image: url("/assets/flags/1x1/pl.svg")
}

.pm {
  background-image: url("/assets/flags/4x3/pm.svg")
}

.pm.fp-square {
  background-image: url("/assets/flags/1x1/pm.svg")
}

.pn {
  background-image: url("/assets/flags/4x3/pn.svg")
}

.pn.fp-square {
  background-image: url("/assets/flags/1x1/pn.svg")
}

.pr {
  background-image: url("/assets/flags/4x3/pr.svg")
}

.pr.fp-square {
  background-image: url("/assets/flags/1x1/pr.svg")
}

.ps {
  background-image: url("/assets/flags/4x3/ps.svg")
}

.ps.fp-square {
  background-image: url("/assets/flags/1x1/ps.svg")
}

.pt {
  background-image: url("/assets/flags/4x3/pt.svg")
}

.pt.fp-square {
  background-image: url("/assets/flags/1x1/pt.svg")
}

.pw {
  background-image: url("/assets/flags/4x3/pw.svg")
}

.pw.fp-square {
  background-image: url("/assets/flags/1x1/pw.svg")
}

.py {
  background-image: url("/assets/flags/4x3/py.svg")
}

.py.fp-square {
  background-image: url("/assets/flags/1x1/py.svg")
}

.qa {
  background-image: url("/assets/flags/4x3/qa.svg")
}

.qa.fp-square {
  background-image: url("/assets/flags/1x1/qa.svg")
}

.re {
  background-image: url("/assets/flags/4x3/re.svg")
}

.re.fp-square {
  background-image: url("/assets/flags/1x1/re.svg")
}

.ro {
  background-image: url("/assets/flags/4x3/ro.svg")
}

.ro.fp-square {
  background-image: url("/assets/flags/1x1/ro.svg")
}

.rs {
  background-image: url("/assets/flags/4x3/rs.svg")
}

.rs.fp-square {
  background-image: url("/assets/flags/1x1/rs.svg")
}

.ru {
  background-image: url("/assets/flags/4x3/ru.svg")
}

.ru.fp-square {
  background-image: url("/assets/flags/1x1/ru.svg")
}

.rw {
  background-image: url("/assets/flags/4x3/rw.svg")
}

.rw.fp-square {
  background-image: url("/assets/flags/1x1/rw.svg")
}

.sa {
  background-image: url("/assets/flags/4x3/sa.svg")
}

.sa.fp-square {
  background-image: url("/assets/flags/1x1/sa.svg")
}

.sb {
  background-image: url("/assets/flags/4x3/sb.svg")
}

.sb.fp-square {
  background-image: url("/assets/flags/1x1/sb.svg")
}

.sc {
  background-image: url("/assets/flags/4x3/sc.svg")
}

.sc.fp-square {
  background-image: url("/assets/flags/1x1/sc.svg")
}

.sd {
  background-image: url("/assets/flags/4x3/sd.svg")
}

.sd.fp-square {
  background-image: url("/assets/flags/1x1/sd.svg")
}

.se {
  background-image: url("/assets/flags/4x3/se.svg")
}

.se.fp-square {
  background-image: url("/assets/flags/1x1/se.svg")
}

.sg {
  background-image: url("/assets/flags/4x3/sg.svg")
}

.sg.fp-square {
  background-image: url("/assets/flags/1x1/sg.svg")
}

.sh {
  background-image: url("/assets/flags/4x3/sh.svg")
}

.sh.fp-square {
  background-image: url("/assets/flags/1x1/sh.svg")
}

.si {
  background-image: url("/assets/flags/4x3/si.svg")
}

.si.fp-square {
  background-image: url("/assets/flags/1x1/si.svg")
}

.sj {
  background-image: url("/assets/flags/4x3/sj.svg")
}

.sj.fp-square {
  background-image: url("/assets/flags/1x1/sj.svg")
}

.sk {
  background-image: url("/assets/flags/4x3/sk.svg")
}

.sk.fp-square {
  background-image: url("/assets/flags/1x1/sk.svg")
}

.sl {
  background-image: url("/assets/flags/4x3/sl.svg")
}

.sl.fp-square {
  background-image: url("/assets/flags/1x1/sl.svg")
}

.sm {
  background-image: url("/assets/flags/4x3/sm.svg")
}

.sm.fp-square {
  background-image: url("/assets/flags/1x1/sm.svg")
}

.sn {
  background-image: url("/assets/flags/4x3/sn.svg")
}

.sn.fp-square {
  background-image: url("/assets/flags/1x1/sn.svg")
}

.so {
  background-image: url("/assets/flags/4x3/so.svg")
}

.so.fp-square {
  background-image: url("/assets/flags/1x1/so.svg")
}

.sr {
  background-image: url("/assets/flags/4x3/sr.svg")
}

.sr.fp-square {
  background-image: url("/assets/flags/1x1/sr.svg")
}

.ss {
  background-image: url("/assets/flags/4x3/ss.svg")
}

.ss.fp-square {
  background-image: url("/assets/flags/1x1/ss.svg")
}

.st {
  background-image: url("/assets/flags/4x3/st.svg")
}

.st.fp-square {
  background-image: url("/assets/flags/1x1/st.svg")
}

.sv {
  background-image: url("/assets/flags/4x3/sv.svg")
}

.sv.fp-square {
  background-image: url("/assets/flags/1x1/sv.svg")
}

.sx {
  background-image: url("/assets/flags/4x3/sx.svg")
}

.sx.fp-square {
  background-image: url("/assets/flags/1x1/sx.svg")
}

.sy {
  background-image: url("/assets/flags/4x3/sy.svg")
}

.sy.fp-square {
  background-image: url("/assets/flags/1x1/sy.svg")
}

.sz {
  background-image: url("/assets/flags/4x3/sz.svg")
}

.sz.fp-square {
  background-image: url("/assets/flags/1x1/sz.svg")
}

.ta {
  background-image: url("/assets/flags/4x3/ta.svg")
}

.ta.fp-square {
  background-image: url("/assets/flags/1x1/ta.svg")
}

.tc {
  background-image: url("/assets/flags/4x3/tc.svg")
}

.tc.fp-square {
  background-image: url("/assets/flags/1x1/tc.svg")
}

.td {
  background-image: url("/assets/flags/4x3/td.svg")
}

.td.fp-square {
  background-image: url("/assets/flags/1x1/td.svg")
}

.tf {
  background-image: url("/assets/flags/4x3/tf.svg")
}

.tf.fp-square {
  background-image: url("/assets/flags/1x1/tf.svg")
}

.tg {
  background-image: url("/assets/flags/4x3/tg.svg")
}

.tg.fp-square {
  background-image: url("/assets/flags/1x1/tg.svg")
}

.th {
  background-image: url("/assets/flags/4x3/th.svg")
}

.th.fp-square {
  background-image: url("/assets/flags/1x1/th.svg")
}

.tj {
  background-image: url("/assets/flags/4x3/tj.svg")
}

.tj.fp-square {
  background-image: url("/assets/flags/1x1/tj.svg")
}

.tk {
  background-image: url("/assets/flags/4x3/tk.svg")
}

.tk.fp-square {
  background-image: url("/assets/flags/1x1/tk.svg")
}

.tl {
  background-image: url("/assets/flags/4x3/tl.svg")
}

.tl.fp-square {
  background-image: url("/assets/flags/1x1/tl.svg")
}

.tm {
  background-image: url("/assets/flags/4x3/tm.svg")
}

.tm.fp-square {
  background-image: url("/assets/flags/1x1/tm.svg")
}

.tn {
  background-image: url("/assets/flags/4x3/tn.svg")
}

.tn.fp-square {
  background-image: url("/assets/flags/1x1/tn.svg")
}

.to {
  background-image: url("/assets/flags/4x3/to.svg")
}

.to.fp-square {
  background-image: url("/assets/flags/1x1/to.svg")
}

.tr {
  background-image: url("/assets/flags/4x3/tr.svg")
}

.tr.fp-square {
  background-image: url("/assets/flags/1x1/tr.svg")
}

.tt {
  background-image: url("/assets/flags/4x3/tt.svg")
}

.tt.fp-square {
  background-image: url("/assets/flags/1x1/tt.svg")
}

.tv {
  background-image: url("/assets/flags/4x3/tv.svg")
}

.tv.fp-square {
  background-image: url("/assets/flags/1x1/tv.svg")
}

.tw {
  background-image: url("/assets/flags/4x3/tw.svg")
}

.tw.fp-square {
  background-image: url("/assets/flags/1x1/tw.svg")
}

.tz {
  background-image: url("/assets/flags/4x3/tz.svg")
}

.tz.fp-square {
  background-image: url("/assets/flags/1x1/tz.svg")
}

.ua {
  background-image: url("/assets/flags/4x3/ua.svg")
}

.ua.fp-square {
  background-image: url("/assets/flags/1x1/ua.svg")
}

.ug {
  background-image: url("/assets/flags/4x3/ug.svg")
}

.ug.fp-square {
  background-image: url("/assets/flags/1x1/ug.svg")
}

.um {
  background-image: url("/assets/flags/4x3/um.svg")
}

.um.fp-square {
  background-image: url("/assets/flags/1x1/um.svg")
}

.us {
  background-image: url("/assets/flags/4x3/us.svg")
}

.us.fp-square {
  background-image: url("/assets/flags/1x1/us.svg")
}

.uy {
  background-image: url("/assets/flags/4x3/uy.svg")
}

.uy.fp-square {
  background-image: url("/assets/flags/1x1/uy.svg")
}

.uz {
  background-image: url("/assets/flags/4x3/uz.svg")
}

.uz.fp-square {
  background-image: url("/assets/flags/1x1/uz.svg")
}

.va {
  background-image: url("/assets/flags/4x3/va.svg")
}

.va.fp-square {
  background-image: url("/assets/flags/1x1/va.svg")
}

.vc {
  background-image: url("/assets/flags/4x3/vc.svg")
}

.vc.fp-square {
  background-image: url("/assets/flags/1x1/vc.svg")
}

.ve {
  background-image: url("/assets/flags/4x3/ve.svg")
}

.ve.fp-square {
  background-image: url("/assets/flags/1x1/ve.svg")
}

.vg {
  background-image: url("/assets/flags/4x3/vg.svg")
}

.vg.fp-square {
  background-image: url("/assets/flags/1x1/vg.svg")
}

.vi {
  background-image: url("/assets/flags/4x3/vi.svg")
}

.vi.fp-square {
  background-image: url("/assets/flags/1x1/vi.svg")
}

.vn {
  background-image: url("/assets/flags/4x3/vn.svg")
}

.vn.fp-square {
  background-image: url("/assets/flags/1x1/vn.svg")
}

.vu {
  background-image: url("/assets/flags/4x3/vu.svg")
}

.vu.fp-square {
  background-image: url("/assets/flags/1x1/vu.svg")
}

.wf {
  background-image: url("/assets/flags/4x3/wf.svg")
}

.wf.fp-square {
  background-image: url("/assets/flags/1x1/wf.svg")
}

.ws {
  background-image: url("/assets/flags/4x3/ws.svg")
}

.ws.fp-square {
  background-image: url("/assets/flags/1x1/ws.svg")
}

.xk {
  background-image: url("/assets/flags/4x3/xk.svg")
}

.xk.fp-square {
  background-image: url("/assets/flags/1x1/xk.svg")
}

.ye {
  background-image: url("/assets/flags/4x3/ye.svg")
}

.ye.fp-square {
  background-image: url("/assets/flags/1x1/ye.svg")
}

.yt {
  background-image: url("/assets/flags/4x3/yt.svg")
}

.yt.fp-square {
  background-image: url("/assets/flags/1x1/yt.svg")
}

.za {
  background-image: url("/assets/flags/4x3/za.svg")
}

.za.fp-square {
  background-image: url("/assets/flags/1x1/za.svg")
}

.zm {
  background-image: url("/assets/flags/4x3/zm.svg")
}

.zm.fp-square {
  background-image: url("/assets/flags/1x1/zm.svg")
}

.zw {
  background-image: url("/assets/flags/4x3/zw.svg")
}

.zw.fp-square {
  background-image: url("/assets/flags/1x1/zw.svg")
}