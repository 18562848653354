/**
 * @license
 *
 * Font Family: Chillax
 * Designed by: Manushi Parikh
 * URL: https://www.fontshare.com/fonts/chillax
 * © 2024 Indian Type Foundry
 *
 * Font Styles:
 * Chillax Variable(Variable font)
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'Chillax-Variable';
  src: url('./assets/fonts/chillax/Chillax-Variable.woff2') format('woff2'),
       url('./assets/fonts/chillax/Chillax-Variable.woff') format('woff'),
       url('./assets/fonts/chillax/Chillax-Variable.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}