/* You can add global styles to this file, and also import other style files */
@import "chillax";
@import "styles/reset";
@import "styles/flagpack";
// @import 'overlay-prebuilt';

@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Fixing the bug where using line clamp will clip the last character.
 */
.line-clamp-1 {
  padding-right: 0.125em;
}

.line-clamp-2 {
  padding-right: 0.125em;
}

.line-clamp-3 {
  padding-right: 0.125em;
}

.line-clamp-4 {
  padding-right: 0.125em;
}

.line-clamp-5 {
  padding-right: 0.125em;
}

.line-clamp-6 {
  padding-right: 0.125em;
}

body, h1, h2, h3, h4, h5, h6, p, span, a, button, textarea, input, q, td, th, li, div {
  font-family: 'Chillax-Variable', sans-serif;;
}

body.p-overflow-hidden {
  overflow: hidden;
}

.business-page #fb-root {
  display: none;
}

.event-page #fb-root {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.no-input {
  pointer-events: none;
}

.cdk-overlay-container .g-btn-close {
  width: 60px;
  height: 60px;
  padding: 20px;
}

.lazyfade {
  opacity: 0;
  transition: opacity 350ms;
}

.lazyfade.lazyloaded {
  opacity: 1;
}

.ql-style-reset {
  .ql-container, .ql-editor {
    font-family: 'Chillax-Variable', sans-serif;

    // THIS SHOULD MATCH [text-sm] VALUES FROM TAILWIND
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
  }

  .ql-editor {
    padding: 0;

    * { user-select: text; }
    > p { user-select: text; }
  }
}

app-seating-browser {
  mat-tooltip-component .mat-mdc-tooltip .mdc-tooltip__surface{
    @apply rounded-lg bg-white text-sm text-default-light;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.12);
  }
}
